// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconStopChat({
  className,
  onClick,
  width = 19,
  height = 19,
  color = "#FFF1E4",
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.17773 6.5L10.9277 10.25M10.9277 6.5L7.17773 10.25M7.47773 14.9L8.57273 16.36C8.73557 16.5771 8.81699 16.6857 8.91681 16.7245C9.00424 16.7585 9.10123 16.7585 9.18866 16.7245C9.28848 16.6857 9.3699 16.5771 9.53273 16.36L10.6277 14.9C10.8476 14.6069 10.9575 14.4603 11.0916 14.3484C11.2704 14.1992 11.4815 14.0936 11.7081 14.0401C11.8781 14 12.0613 14 12.4277 14C13.4761 14 14.0003 14 14.4138 13.8287C14.9651 13.6004 15.4031 13.1624 15.6315 12.611C15.8027 12.1976 15.8027 11.6734 15.8027 10.625V6.35C15.8027 5.08988 15.8027 4.45982 15.5575 3.97852C15.3418 3.55516 14.9976 3.21095 14.5742 2.99524C14.0929 2.75 13.4629 2.75 12.2027 2.75H5.90273C4.64262 2.75 4.01256 2.75 3.53126 2.99524C3.10789 3.21095 2.76368 3.55516 2.54797 3.97852C2.30273 4.45982 2.30273 5.08988 2.30273 6.35V10.625C2.30273 11.6734 2.30273 12.1976 2.47401 12.611C2.70237 13.1624 3.14038 13.6004 3.6917 13.8287C4.10518 14 4.62937 14 5.67773 14C6.04417 14 6.22739 14 6.39736 14.0401C6.624 14.0936 6.83507 14.1992 7.01386 14.3484C7.14794 14.4603 7.25787 14.6069 7.47773 14.9Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
