import { FormDataType, JSON_CONTENT_TYPE } from "./ChatForm";
import { API_URL } from "../../helpers/constants";

const CHAT_API_URL = `${API_URL}/chat`;

export const postChatData = async (data: FormDataType, tag: string) => {
  const response = await fetch(CHAT_API_URL, {
    method: "POST",
    headers: JSON_CONTENT_TYPE,
    body: JSON.stringify({
      email: data.email,
      chat: data.chat,
      tag,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response;
};
