// @flow
import * as React from "react";

export type IndicatorProps = {
  width?: number;
  height?: number;
};

export function IndicatorGood({ width = 111, height = 73 }: IndicatorProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 111 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_438_7766)">
        <path
          d="M48.1377 46.5576C48.1377 46.0868 47.756 45.7052 47.2852 45.7052H47.2631C47.8272 44.9717 48.1346 44.0731 48.1377 43.1478C48.1377 40.7938 46.2294 38.8855 43.8754 38.8855C41.5214 38.8855 39.6131 40.7938 39.6131 43.1478C39.6162 44.0731 39.9236 44.9717 40.4877 45.7052H40.4656C39.9948 45.7052 39.6131 46.0868 39.6131 46.5576C39.6131 47.0284 39.9948 47.4101 40.4656 47.4101H47.2852C47.756 47.4101 48.1377 47.0284 48.1377 46.5576ZM41.318 43.1478C41.318 41.7354 42.463 40.5904 43.8754 40.5904C45.2878 40.5904 46.4328 41.7354 46.4328 43.1478C46.4328 44.5602 45.2878 45.7052 43.8754 45.7052C42.463 45.7052 41.318 44.5602 41.318 43.1478Z"
          fill="#0AA781"
        />
        <path
          d="M57.5147 38.8855C55.1619 38.8883 53.2553 40.795 53.2524 43.1478C53.2556 44.0731 53.5629 44.9717 54.1271 45.7052H54.1049C53.6341 45.7052 53.2524 46.0868 53.2524 46.5576C53.2524 47.0284 53.6341 47.4101 54.1049 47.4101H60.9246C61.3954 47.4101 61.777 47.0284 61.777 46.5576C61.777 46.0868 61.3954 45.7052 60.9246 45.7052H60.9024C61.4666 44.9717 61.7739 44.0731 61.777 43.1478C61.7742 40.795 59.8676 38.8883 57.5147 38.8855ZM54.9574 43.1478C54.9574 41.7354 56.1023 40.5904 57.5147 40.5904C58.9271 40.5904 60.0721 41.7354 60.0721 43.1478C60.0721 44.5602 58.9271 45.7052 57.5147 45.7052C56.1023 45.7052 54.9574 44.5602 54.9574 43.1478Z"
          fill="#0AA781"
        />
        <path
          d="M44.7279 44.0001C45.1987 44.0001 45.5803 43.6184 45.5803 43.1476C45.5803 42.6768 45.1987 42.2952 44.7279 42.2952C44.2571 42.2952 43.8754 42.6768 43.8754 43.1476C43.8754 43.6184 44.2571 44.0001 44.7279 44.0001Z"
          fill="#0AA781"
        />
        <path
          d="M56.6623 44.0001C57.1331 44.0001 57.5147 43.6184 57.5147 43.1476C57.5147 42.6768 57.1331 42.2952 56.6623 42.2952C56.1915 42.2952 55.8098 42.6768 55.8098 43.1476C55.8098 43.6184 56.1915 44.0001 56.6623 44.0001Z"
          fill="#0AA781"
        />
        <path
          d="M54.9574 49.115H46.4328C45.962 49.115 45.5803 49.4966 45.5803 49.9674V50.8199C45.5803 53.6447 47.8703 55.9347 50.6951 55.9347C53.5199 55.9347 55.8098 53.6447 55.8098 50.8199V49.9674C55.8098 49.4966 55.4282 49.115 54.9574 49.115ZM50.6951 54.2297C48.8119 54.2297 47.2852 52.7031 47.2852 50.8199H54.1049C54.1049 52.7031 52.5783 54.2297 50.6951 54.2297Z"
          fill="#0AA781"
        />
        <path
          d="M46.4268 32.0657C46.3721 31.5982 45.9489 31.2636 45.4814 31.3181C41.8601 31.6179 38.6024 33.6369 36.7224 36.7465C36.4828 37.1519 36.6171 37.6748 37.0225 37.9144C37.4279 38.154 37.9507 38.0197 38.1904 37.6143C39.8058 34.9695 42.59 33.2582 45.6792 33.0111C46.1467 32.9563 46.4813 32.5331 46.4268 32.0657Z"
          fill="#0AA781"
        />
        <path
          d="M70.3017 32.0658H70.2914C70.2872 32.0658 70.2837 32.0658 70.2786 32.0658C65.1649 32.0634 60.0867 31.2166 55.2489 29.5595C52.7127 28.5283 50.02 27.9346 47.2852 27.8035H47.2094C39.6131 27.8035 33.7763 32.2917 31.9666 39.5307C30.599 45.2675 30.0126 51.1627 30.2233 57.0564C30.2301 57.5781 30.2361 58.0504 30.2361 58.4698C30.2361 62.8182 32.7218 65.3116 37.0557 65.3116C38.2779 65.2924 39.4762 64.9702 40.5431 64.3739C42.0648 63.6528 43.9607 62.7543 48.9902 62.7543C56.4671 62.7543 63.5723 61.8115 68.4407 52.1267C79.8015 47.3307 81.3836 42.1111 81.3836 37.1805C81.3836 32.0658 73.899 32.0658 70.3017 32.0658ZM48.9902 61.0494C43.5762 61.0494 41.4033 62.0808 39.8117 62.8353C38.9725 63.3199 38.0246 63.5852 37.0557 63.6067C34.7226 63.6067 31.941 62.7159 31.941 58.4698C31.941 58.0436 31.935 57.5645 31.9282 57.0351C31.7211 51.2883 32.2903 45.5397 33.6203 39.945C35.2553 33.405 40.3326 29.5084 47.2102 29.5084H47.2852C49.8218 29.6423 52.3179 30.2014 54.6693 31.1622C59.4851 32.8274 64.5372 33.7081 69.6325 33.7707C70.4307 37.3414 70.3793 41.0497 69.4824 44.5969C68.8622 46.8419 68.0432 49.0272 67.035 51.1267C67.035 51.1353 67.0265 51.1429 67.0231 51.1515C62.5707 60.185 56.1738 61.0494 48.9902 61.0494ZM69.4978 49.7969C70.1258 48.2518 70.668 46.6732 71.1217 45.0683C72.0802 41.3759 72.1677 37.5113 71.3775 33.7792C74.6253 33.8133 79.677 34.1372 79.677 37.1839C79.6787 41.0055 78.851 45.4136 69.4978 49.7969Z"
          fill="#0AA781"
        />
      </g>
      <path
        d="M105.384 55.5576C105.384 27.9434 82.9978 5.55762 55.3836 5.55762C27.7694 5.55762 5.38361 27.9434 5.38361 55.5576"
        stroke="#CCF5EA"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M5.38361 55.5579C5.38361 36.7823 15.7325 20.4239 31.0372 11.8755"
        stroke="#0AA781"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="clip0_438_7766">
          <rect
            width="52"
            height="52"
            fill="white"
            transform="translate(29.3836 20.5576)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
