import React, { useEffect } from "react";
import "./App.css";
import { ChatForm } from "./components/ChatForm/ChatForm";

function App() {
  const [formJson, setFormJson] = React.useState([]); // [state, setState

  useEffect(() => {
    fetch("/formJson.json")
      .then((response) => response.json())
      .then((data) => {
        setFormJson(data.form);
      });
  }, []);

  return (
    <>
      <picture>
        <source srcSet={`/images/bg_desktop.webp`} type="image/webp" />
        <source srcSet={`/images/bg_desktop.png`} type="image/png" />
        <img
          src={`/images/bg_desktop.png`}
          alt=""
          className="absolute left-0 top-0 -z-10 hidden sm:block"
        />
      </picture>

      <div className="container relative mx-auto flex h-[calc(100svh-77px)] flex-col bg-zhiva-brown-5 lg:h-[calc(100svh-30px)] lg:max-w-[634px]">
        <ChatForm formJson={formJson} />
        {/*<GptForm
        chatID={6}
        answersPrompts={
          "Užívateľ je žena.|Užívateľ má 70 rokov.|Má index telesnej hmotnosti 24, čo znamená, že má normálnu hmotnosť.|Denne skonzumuje viac ako 600 gramov ovocia a zeleniny.|Konzumuje alkohol menej ako trikrát týždenne.|Užívateľovo riziko vzniku nealkoholovej tukovej choroby pečene je mierne."
        }
        onGPTStart={() => console.log("gptstarted")}
        onTokensChange={(max: number, left: number) => console.log(max, left)}
      />*/}
      </div>

      <div className="hidden h-[30px] w-full bg-black lg:flex"></div>
    </>
  );
}

export default App;
