// @flow
import * as React from "react";
import { MenuButton, ProgressBarMobile } from "./MobileFormMenu";
import { IconStopChat } from "../icons/IconStopChat";
import { MobileMenuLayout } from "../layout/MobileMenuLayout";

type MobileFormMenuProps = {
  onStopChat: () => void;
  actualStep: number;
  maxSteps: number;
};

export function MobileGPTMenu({
  onStopChat,
  actualStep,
  maxSteps,
}: MobileFormMenuProps) {
  return (
    <MobileMenuLayout>
      <ProgressBarMobile
        variant={"gpt"}
        actualStep={actualStep}
        maxSteps={maxSteps}
      />
      <div className="flex h-20 flex-row bg-black">
        <div className="flex w-full flex-row justify-center gap-4">
          <MenuButton
            onClick={onStopChat}
            icon={<IconStopChat />}
            text={"Ukončiť chat"}
          />
          <MenuButton type="bot" text={"Limit konverzácie"} />
          <MenuButton type="share" />
        </div>
      </div>
    </MobileMenuLayout>
  );
}
