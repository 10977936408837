// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconRestart({
  className,
  onClick,
  width = 19,
  height = 19,
  color = "#FFF1E4",
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.05273 8C2.05273 8 3.55647 5.95116 4.77811 4.72868C5.99975 3.5062 7.68793 2.75 9.55273 2.75C13.2807 2.75 16.3027 5.77208 16.3027 9.5C16.3027 13.2279 13.2807 16.25 9.55273 16.25C6.47541 16.25 3.87907 14.1907 3.06656 11.375M2.05273 8V3.5M2.05273 8H6.55273"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
