// @flow
import * as React from "react";

type DesktopMenuLayoutProps = {
  children: React.ReactNode;
};

export function DesktopMenuLayout({ children }: DesktopMenuLayoutProps) {
  return (
    <div className="absolute right-[calc(100%+20px)] hidden w-[170px] lg:mt-20 lg:block xl:right-[calc(100%+80px)]">
      {children}
    </div>
  );
}
