// @flow
import * as React from "react";
import { useCallback } from "react";
import { FieldValues, FormState, UseFormRegister } from "react-hook-form";
import { IconSend } from "../icons/IconSend";
import { cn } from "../helpers/twMerge";
import { ErrorMessage } from "@hookform/error-message";
import { motion } from "framer-motion";
import { FAKE_LOADING_TIME } from "../helpers/constants";

type InputProps = {
  hide: string | undefined | number;
  register: UseFormRegister<any>;
  name: string;
  onSend?: (name: string, key: string) => void;
  answerKey: string;
  type?: string;
  formState: FormState<FieldValues>;
};

export function Input({
  hide,
  register,
  onSend,
  name,
  answerKey,
  type = "number",
  formState: { errors, isValid },
}: InputProps) {
  const emailPattern = {
    value: /^\S+@\S+\.\S+$/,
    message: "Zadajte platnú e-mailovú adresu.",
  };

  /**
   * Returns the minimum value and a message based on the given name.
   *
   * @param {string} name - The name of the data.
   * @returns {object|undefined} - An object with the minimum value and message, or undefined if the name is not recognized.
   */
  const getMin = (name: string): { value: number; message: string } => {
    const message =
      "Zdá sa mi, že tento údaj nie je správny. Prosím, upravte ho a odošlite ešte raz";

    if (name === "vek") {
      return {
        value: 12,
        message: message,
      };
    }

    if (name === "vyska") {
      return {
        value: 140,
        message: message,
      };
    }

    if (name === "hmotnost") {
      return {
        value: 35,
        message: message,
      };
    }

    return {
      value: 0,
      message: message,
    };
  };

  const getMax = (name: string): { value: number; message: string } => {
    const message =
      "Zdá sa mi, že tento údaj nie je správny. Prosím, upravte ho a odošlite ešte raz";

    if (name === "vek") {
      return {
        value: 100,
        message: message,
      };
    }

    if (name === "vyska") {
      return {
        value: 220,
        message: message,
      };
    }

    if (name === "hmotnost") {
      return {
        value: 250,
        message: message,
      };
    }

    return {
      value: 0,
      message: message,
    };
  };

  const { ref, ...rest } = register(name, {
    required: true,
    pattern: type === "email" ? emailPattern : undefined,
    min: type === "number" ? getMin(name) : undefined,
    max: type === "number" ? getMax(name) : undefined,
  });

  const inputRef = useCallback(
    function (node: HTMLInputElement) {
      if (node) {
        setTimeout(() => {
          node.focus();
        }, FAKE_LOADING_TIME);
      }

      ref(node);
    },
    [ref],
  );

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: [0, 1],
          translateY: [100, -10, 0],
        }}
        transition={{
          type: "spring",
          mass: 1,
          stiffness: 100,
          damping: 15,
          delay: parseFloat(`0.${FAKE_LOADING_TIME}`) + 0.4,
          duration: 0.4,
        }}
        className={cn("relative flex w-full", {
          hidden: hide,
        })}
      >
        <input
          ref={inputRef}
          {...rest}
          className={cn(
            "h-12 w-full rounded-[32px] border border-zhiva-brown bg-white p-2 px-5 py-2.5",
            {
              "border-warning-red":
                errors[name]?.type === "pattern" ||
                errors[name]?.type === "min" ||
                errors[name]?.type === "max",
            },
          )}
          type={type}
          // Add on "keypress enter" event
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Check if the input is valid
              if (isValid) {
                onSend?.(name, answerKey);
              }
            }
          }}
        />
        <button
          className="absolute bottom-1 right-1 top-1"
          type="button"
          onClick={() => {
            if (isValid) {
              onSend?.(name, answerKey);
            }
          }}
        >
          <IconSend />
        </button>
      </motion.div>

      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className="ml-5 w-full text-sm text-warning-red">{message}</p>
          )}
        />
      )}
    </>
  );
}
