// @flow
import * as React from "react";
import { DesktopShareIcons } from "./DesktopFormMenu";
import { handleShare } from "./MobileFormMenu";
import { FAKE_LOADING_TIME, SHARE_LINK_FINAL } from "../helpers/constants";
import { motion } from "framer-motion";

type ChatBubbleProps = {
  loading?: boolean;
  title?: string;
  text?: string;
  imageName?: string;
  share?: boolean;
  className?: string;
};

function ChatBubbleTitle(props: { title: string }) {
  return (
    <h2 className="text-lg font-bold leading-6 text-zhiva-brown">
      {props.title}
    </h2>
  );
}

function ChatBubbleText({ text }: { text: string }) {
  return (
    <p
      className="text-base font-medium leading-6 text-zhiva-brown [&_a:hover]:no-underline [&_a]:cursor-pointer [&_a]:font-semibold [&_a]:text-mint-green [&_a]:underline"
      dangerouslySetInnerHTML={{ __html: text }}
    ></p>
  );
}

function ChatBubbleImage(props: { imageName: string }) {
  return (
    <picture>
      <source srcSet={`/images/${props.imageName}.webp`} type="image/webp" />
      <source srcSet={`/images/${props.imageName}.png`} type="image/png" />
      <img
        src={`/images/${props.imageName}.png`}
        alt=""
        className="aspect-video h-auto w-full rounded-lg object-cover object-center"
      />
    </picture>
  );
}

export function ChatBubble({
  loading,
  title,
  text,
  imageName,
  share = false,
  className,
}: ChatBubbleProps) {
  const [bubbleLoading, setBubbleLoading] = React.useState(loading ?? true);

  React.useEffect(() => {
    let timeoutId: any;

    if (bubbleLoading) {
      timeoutId = setTimeout(() => {
        setBubbleLoading(false);
      }, FAKE_LOADING_TIME);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [bubbleLoading]);

  return (
    <div
      className={
        `flex w-fit max-w-[300px] flex-col gap-4 rounded-2xl border border-zhiva-brown-25 bg-white px-5 py-4 text-left sm:max-w-[465px]` +
        (className ? ` ${className}` : "")
      }
    >
      {bubbleLoading ? (
        <div className="flex flex-row gap-1">
          <motion.div
            className="h-2 w-2 rounded-full bg-[#D9D9D9]"
            animate={{
              opacity: [1, 0, 1],
              scale: [1, 0.5, 1],
            }}
            transition={{
              delay: 0,
              duration: 1,
              repeatType: "loop",
              repeat: Infinity,
            }}
          ></motion.div>
          <motion.div
            className="h-2 w-2 rounded-full bg-[#D9D9D9]"
            animate={{
              opacity: [1, 0, 1],
              scale: [1, 0.5, 1],
            }}
            transition={{
              delay: 0.2,
              duration: 1,
              repeatType: "loop",
              repeat: Infinity,
            }}
          ></motion.div>
          <motion.div
            className="h-2 w-2 rounded-full bg-[#D9D9D9]"
            animate={{
              opacity: [1, 0, 1],
              scale: [1, 0.5, 1],
            }}
            transition={{
              delay: 0.4,
              duration: 1,
              repeatType: "loop",
              repeat: Infinity,
            }}
          ></motion.div>
        </div>
      ) : (
        <>
          {title ? <ChatBubbleTitle title={title} /> : null}
          {text ? <ChatBubbleText text={text} /> : null}
          {imageName ? <ChatBubbleImage imageName={imageName} /> : null}

          {share ? (
            <>
              <div className="mt-9 hidden sm:flex">
                <DesktopShareIcons url={SHARE_LINK_FINAL} />
              </div>
              <div className={"flex w-full justify-center sm:hidden"}>
                <button
                  onClick={handleShare}
                  className="rounded-full border border-zhiva-brown px-4 py-3"
                >
                  <span className="text-base font-semibold leading-4">
                    Zdieľať
                  </span>
                </button>
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
