// @flow
import * as React from "react";

export type IndicatorProps = {
  width?: number;
  height?: number;
};

export function IndicatorBad({ width = 111, height = 73 }: IndicatorProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 111 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.383 55.1293C105.383 27.5151 82.9977 5.12939 55.3835 5.12939C27.7693 5.12939 5.38361 27.5151 5.38361 55.1293"
        stroke="#FAC4C4"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M105.384 55.1294C105.384 36.3731 95.0561 20.029 79.7772 11.4734"
        stroke="#C00909"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <g clipPath="url(#clip0_438_7799)">
        <path
          d="M47.2341 48.1241C47.356 47.7868 47.2538 47.4093 46.9784 47.1796L41.8636 42.9173C41.6297 42.7221 41.3094 42.6665 41.0234 42.7714C40.7374 42.8764 40.5291 43.126 40.477 43.4261C40.4249 43.7263 40.5369 44.0315 40.7708 44.2267L44.0783 46.9818H39.6131C39.1423 46.9818 38.7607 47.3635 38.7607 47.8343C38.7607 48.3051 39.1423 48.6868 39.6131 48.6868H46.4328C46.7917 48.6866 47.1121 48.4617 47.2341 48.1241Z"
          fill="#C00909"
        />
        <path
          d="M61.777 46.9818H57.3119L60.6186 44.2267C60.8524 44.0315 60.9644 43.7263 60.9123 43.4261C60.8602 43.126 60.6519 42.8764 60.3659 42.7714C60.0799 42.6665 59.7596 42.7221 59.5257 42.9173L54.4109 47.1796C54.1353 47.4095 54.0331 47.7874 54.1555 48.1249C54.2778 48.4623 54.5984 48.687 54.9574 48.6868H61.777C62.2478 48.6868 62.6295 48.3051 62.6295 47.8343C62.6295 47.3635 62.2478 46.9818 61.777 46.9818Z"
          fill="#C00909"
        />
        <path
          d="M55.3384 51.3294C53.8752 50.6892 52.292 50.3695 50.6951 50.3917C49.0981 50.3695 47.5149 50.6892 46.0517 51.3294C45.6301 51.5392 45.4584 52.051 45.6681 52.4726C45.8779 52.8942 46.3897 53.0659 46.8113 52.8562C48.0395 52.3375 49.3619 52.0789 50.6951 52.0966C52.0279 52.0814 53.3495 52.3426 54.5763 52.8638C54.8489 53 55.1734 52.9803 55.4276 52.8124C55.6818 52.6444 55.827 52.3535 55.8086 52.0494C55.7902 51.7453 55.611 51.4741 55.3384 51.3379V51.3294Z"
          fill="#C00909"
        />
        <path
          d="M45.6792 32.5862C46.1382 32.5222 46.462 32.1033 46.4082 31.643C46.3544 31.1827 45.9428 30.8497 45.4814 30.8932C41.8606 31.192 38.603 33.2097 36.7224 36.3183C36.5595 36.5807 36.5513 36.9108 36.701 37.181C36.8507 37.4512 37.1349 37.6193 37.4438 37.6203C37.7527 37.6214 38.038 37.4553 38.1895 37.1861C39.8062 34.5427 42.5904 32.8327 45.6792 32.5862Z"
          fill="#C00909"
        />
        <path
          d="M70.3016 31.6375H70.2923C70.2871 31.6375 70.2837 31.6375 70.2795 31.6375C65.1656 31.6342 60.0872 30.7874 55.2489 29.1313C52.7127 28.1 50.02 27.5062 47.2852 27.3752H47.2094C39.6131 27.3752 33.7755 31.8634 31.9666 39.1025C30.5988 44.839 30.0121 50.7339 30.2224 56.6274C30.2301 57.1499 30.2361 57.6222 30.2361 58.0416C30.2361 62.39 32.7218 64.8834 37.0557 64.8834C38.2779 64.864 39.4761 64.5418 40.5431 63.9457C42.0656 63.2246 43.9607 62.3261 48.9902 62.3261C56.4671 62.3261 63.5723 61.3832 68.4399 51.6985C79.8014 46.9025 81.3836 41.6829 81.3836 36.7523C81.3836 31.6375 73.899 31.6375 70.3016 31.6375ZM48.9902 60.6211C43.5762 60.6211 41.4033 61.6526 39.8117 62.407C38.9724 62.8916 38.0246 63.1569 37.0557 63.1785C34.7226 63.1785 31.941 62.2877 31.941 58.0416C31.941 57.6154 31.935 57.1354 31.9273 56.6061C31.7211 50.8595 32.2905 45.1113 33.6203 39.5168C35.2553 32.9768 40.3326 29.0802 47.2102 29.0802H47.2852C49.8218 29.2142 52.3178 29.7732 54.6692 30.7339C59.4851 32.3991 64.5372 33.2799 69.6325 33.3425C70.4308 36.9132 70.3791 40.6216 69.4816 44.1687C68.8619 46.4135 68.0431 48.5984 67.035 50.6977C67.035 50.707 67.0265 50.7147 67.0231 50.7232C62.5715 59.7568 56.1738 60.6211 48.9902 60.6211ZM69.4978 49.3687C70.1258 47.8236 70.668 46.245 71.1217 44.6401C72.0801 40.9477 72.1677 37.083 71.3774 33.351C74.6253 33.3851 79.677 33.709 79.677 36.7557C79.6787 40.5773 78.8509 44.9853 69.4978 49.3687Z"
          fill="#C00909"
        />
        <path
          d="M56.4918 40.955C56.5479 40.9662 56.6051 40.9719 56.6623 40.972C57.0671 40.9717 57.4159 40.6867 57.4969 40.2901L57.7875 38.8409L58.4695 39.5229C58.6918 39.7453 59.0185 39.8273 59.3195 39.7362C59.6205 39.6451 59.8469 39.3956 59.9085 39.0872L60.5112 36.073C60.596 35.6147 60.297 35.1732 59.84 35.0819C59.383 34.9905 58.9374 35.2831 58.8395 35.7388L58.5488 37.188L57.8668 36.506C57.6445 36.284 57.3181 36.2023 57.0174 36.2934C56.7167 36.3845 56.4905 36.6336 56.4287 36.9416L55.826 39.955C55.7355 40.4147 56.0328 40.8612 56.4918 40.955Z"
          fill="#C00909"
        />
        <path
          d="M44.3724 36.2895C44.0707 36.1973 43.7428 36.2793 43.5199 36.5026L42.838 37.1846L42.5473 35.7354C42.4494 35.2797 42.0037 34.9871 41.5467 35.0785C41.0897 35.1698 40.7908 35.6113 40.8756 36.0695L41.4783 39.0838C41.5399 39.3922 41.7663 39.6417 42.0673 39.7328C42.3683 39.8239 42.695 39.7419 42.9173 39.5194L43.5992 38.8375L43.8899 40.2866C43.9709 40.6833 44.3196 40.9683 44.7245 40.9686C44.7817 40.9685 44.8388 40.9628 44.895 40.9516C45.3566 40.8591 45.6558 40.4098 45.5633 39.9482L44.9606 36.9348C44.8979 36.628 44.6721 36.3802 44.3724 36.2895Z"
          fill="#C00909"
        />
        <path
          d="M49.8426 38.4573H50.8076L49.9858 39.6891C49.7245 40.0808 49.8303 40.6102 50.222 40.8715C50.6137 41.1328 51.143 41.0271 51.4043 40.6353L53.1092 38.078C53.2838 37.8164 53.3001 37.48 53.1518 37.2027C53.0034 36.9254 52.7145 36.7524 52.4 36.7524H51.435L52.2568 35.5206C52.5181 35.1289 52.4124 34.5995 52.0207 34.3382C51.629 34.0769 51.0996 34.1827 50.8383 34.5744L49.1334 37.1317C48.9589 37.3933 48.9425 37.7297 49.0909 38.007C49.2392 38.2843 49.5282 38.4574 49.8426 38.4573Z"
          fill="#C00909"
        />
      </g>
      <defs>
        <clipPath id="clip0_438_7799">
          <rect
            width="52"
            height="52"
            fill="white"
            transform="translate(29.3836 20.1294)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
