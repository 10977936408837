// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconSend({
  width = 41,
  height = 41,
  onClick,
  color = "#0AA781",
  className,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.552734"
        y="0.48584"
        width="40"
        height="40"
        rx="20"
        fill={color}
      />
      <path
        d="M19.0523 21.9859L29.5523 11.4859M19.1798 22.314L21.8079 29.072C22.0395 29.6673 22.1552 29.965 22.322 30.0519C22.4666 30.1272 22.6389 30.1273 22.7836 30.0522C22.9505 29.9654 23.0666 29.6679 23.2988 29.0728L29.8892 12.1851C30.0988 11.6479 30.2036 11.3793 30.1463 11.2077C30.0965 11.0586 29.9795 10.9417 29.8305 10.8919C29.6588 10.8345 29.3902 10.9394 28.8531 11.149L11.9653 17.7393C11.3702 17.9716 11.0727 18.0877 10.986 18.2546C10.9108 18.3993 10.9109 18.5715 10.9863 18.7161C11.0732 18.8829 11.3708 18.9987 11.9662 19.2302L18.7242 21.8583C18.845 21.9053 18.9055 21.9288 18.9563 21.9651C19.0014 21.9973 19.0409 22.0367 19.073 22.0818C19.1093 22.1327 19.1328 22.1931 19.1798 22.314Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
