// @flow
import * as React from "react";
import { DesktopMenuLayout } from "../layout/DesktopMenuLayout";
import { DesktopShareIcons, ProgressBarDesktop } from "./DesktopFormMenu";
import { SHARE_LINK } from "../helpers/constants";
import { Button } from "./Button";

type DesktopFormMenuProps = {
  actualStep: number;
  maxSteps: number;
  onStopChat: () => void;
};

export function DesktopGPTMenu({
  actualStep,
  maxSteps,
  onStopChat,
}: DesktopFormMenuProps) {
  return (
    <DesktopMenuLayout>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <p className="text-base font-bold leading-4 text-zhiva-brown">
            Limit konverzácie
          </p>
          <ProgressBarDesktop
            variant={"gpt"}
            actualStep={actualStep}
            maxSteps={maxSteps}
          />
        </div>

        <div className="w-full">
          <Button text={"Ukončiť chat"} onClick={onStopChat} />
        </div>

        <div className="flex flex-col gap-4">
          <p className="text-base font-bold leading-4 text-zhiva-brown">
            Zdieľať
          </p>
          <DesktopShareIcons url={SHARE_LINK} />
        </div>
      </div>
    </DesktopMenuLayout>
  );
}
