// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconArrowTop({
  className,
  onClick,
  color = "#FFB16E",
  height = 19,
  width = 19,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.05273 15.5V3.5M9.05273 3.5L4.55273 8M9.05273 3.5L13.5527 8"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
