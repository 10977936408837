// @flow
import * as React from "react";

type MobileMenuLayoutProps = {
  children: React.ReactNode;
};

export function MobileMenuLayout({ children }: MobileMenuLayoutProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 lg:hidden">{children}</div>
  );
}
