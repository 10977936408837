// @flow
import * as React from "react";
import { IconRestart } from "../icons/IconRestart";
import { IconShare } from "../icons/IconShare";
import { MobileMenuLayout } from "../layout/MobileMenuLayout";
import { SHARE_LINK } from "../helpers/constants";
import { IconArrowTop } from "../icons/IconArrowTop";
import { motion } from "framer-motion";

type MobileFormMenuProps = {
  onRestart: () => void;
  actualStep: number;
  maxSteps: number;
};

export type ProgressBarProps = {
  variant: "form" | "gpt";
  actualStep: number;
  maxSteps: number;
};

export const getWidht = (
  variant: string,
  actualStep: number,
  maxSteps: number,
) => {
  return Math.round((actualStep / maxSteps) * 100);
};

export function ProgressBarMobile({
  variant,
  actualStep,
  maxSteps,
}: ProgressBarProps) {
  let widthPercentage = `${getWidht(variant, actualStep, maxSteps)}%`;

  return (
    <div
      className={`flex-start flex h-2 flex-row items-center ${
        variant === "form" ? "bg-mint-green-25" : "bg-energy-orange-25"
      }`}
    >
      <motion.div
        initial={{ width: "0%" }}
        animate={{ width: widthPercentage }}
        transition={{ duration: 0.4 }}
        className={`block h-full rounded-br-lg rounded-tr-lg ${
          variant === "form" ? "bg-mint-green" : "bg-energy-orange"
        }`}
      ></motion.div>
    </div>
  );
}

export type MenuButtonProps = {
  onClick?: () => void;
  type?: "button" | "share" | "bot";
  text?: string;
  icon?: React.ReactNode;
};

export const handleShare = () => {
  // On mobile devices, navigator.share is only available in secure contexts (HTTPS).
  if (navigator.share) {
    navigator
      .share({
        title: "Živá",
        text: "Zistite, či máte nadváhu alebo obezitu",
        url: SHARE_LINK,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    console.log("Share not supported");
  }
};

export function MenuButton({ onClick, icon, type, text }: MenuButtonProps) {
  switch (type) {
    case "share":
      return (
        <button
          className="flex min-w-[100px] flex-col items-center justify-center gap-1 text-center text-xs text-energy-orange-10"
          onClick={handleShare}
          type="button"
        >
          <IconShare />
          Zdieľať
        </button>
      );
    case "bot":
      return (
        <div className="flex min-w-[100px] flex-col items-center justify-center gap-1 text-center text-xs text-[#FFB16E]">
          <IconArrowTop />
          {text}
        </div>
      );
    default:
      return (
        <button
          className="flex min-w-[100px] flex-col items-center justify-center gap-1 text-center text-xs text-energy-orange-10"
          onClick={onClick}
          type="button"
        >
          {icon}
          {text}
        </button>
      );
  }
}

export function MobileFormMenu({
  onRestart,
  actualStep,
  maxSteps,
}: MobileFormMenuProps) {
  const handleRestart = () => {
    onRestart();
  };

  return (
    <MobileMenuLayout>
      <ProgressBarMobile
        variant={"form"}
        actualStep={actualStep}
        maxSteps={maxSteps}
      />
      <div className="flex h-20 flex-row bg-black">
        <div className="flex w-full flex-row justify-center gap-4">
          <MenuButton
            onClick={handleRestart}
            icon={<IconRestart />}
            text={"Reštartovať"}
          />
          <MenuButton type="share" />
        </div>
      </div>
    </MobileMenuLayout>
  );
}
