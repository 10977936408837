// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconTwitter({
  className,
  onClick,
  width = 28,
  height = 28,
  color = "#0AA781",
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2033 7.00008C25.305 7.40841 24.3366 7.67675 23.3333 7.80508C24.36 7.18675 25.1533 6.20675 25.5266 5.02841C24.5583 5.61175 23.485 6.02008 22.3533 6.25341C21.4316 5.25008 20.1366 4.66675 18.6666 4.66675C15.925 4.66675 13.685 6.90675 13.685 9.67175C13.685 10.0684 13.7316 10.4534 13.8133 10.8151C9.65996 10.6051 5.96163 8.61008 3.49996 5.58841C3.0683 6.32341 2.8233 7.18675 2.8233 8.09675C2.8233 9.83508 3.6983 11.3751 5.05163 12.2501C4.2233 12.2501 3.4533 12.0167 2.77663 11.6667V11.7017C2.77663 14.1284 4.5033 16.1584 6.78996 16.6134C6.05581 16.8143 5.28508 16.8423 4.5383 16.6951C4.85517 17.6896 5.47576 18.5599 6.31282 19.1835C7.14988 19.8071 8.16132 20.1527 9.20496 20.1717C7.43587 21.5723 5.24296 22.3293 2.98663 22.3184C2.58996 22.3184 2.1933 22.2951 1.79663 22.2484C4.0133 23.6717 6.64996 24.5001 9.4733 24.5001C18.6666 24.5001 23.7183 16.8701 23.7183 10.2551C23.7183 10.0334 23.7183 9.82341 23.7066 9.60175C24.6866 8.90175 25.5266 8.01508 26.2033 7.00008Z"
        fill={color}
      />
    </svg>
  );
}
