// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconShare({
  className,
  onClick,
  color = "#FFF1E4",
  height = 19,
  width = 19,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      className={className}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99523 10.6325L12.1177 13.6175M12.1102 5.3825L6.99523 8.3675M16.3027 4.25C16.3027 5.49264 15.2954 6.5 14.0527 6.5C12.8101 6.5 11.8027 5.49264 11.8027 4.25C11.8027 3.00736 12.8101 2 14.0527 2C15.2954 2 16.3027 3.00736 16.3027 4.25ZM7.30273 9.5C7.30273 10.7426 6.29537 11.75 5.05273 11.75C3.81009 11.75 2.80273 10.7426 2.80273 9.5C2.80273 8.25736 3.81009 7.25 5.05273 7.25C6.29537 7.25 7.30273 8.25736 7.30273 9.5ZM16.3027 14.75C16.3027 15.9926 15.2954 17 14.0527 17C12.8101 17 11.8027 15.9926 11.8027 14.75C11.8027 13.5074 12.8101 12.5 14.0527 12.5C15.2954 12.5 16.3027 13.5074 16.3027 14.75Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
