// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconFacebook({
  className,
  onClick,
  width = 28,
  height = 28,
  color = "#0AA781",
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2617 14.0263C24.2617 8.21579 19.5577 3.5 13.7617 3.5C7.96572 3.5 3.26172 8.21579 3.26172 14.0263C3.26172 19.1211 6.87372 23.3632 11.6617 24.3421V17.1842H9.56172V14.0263H11.6617V11.3947C11.6617 9.36316 13.3102 7.71053 15.3367 7.71053H17.9617V10.8684H15.8617C15.2842 10.8684 14.8117 11.3421 14.8117 11.9211V14.0263H17.9617V17.1842H14.8117V24.5C20.1142 23.9737 24.2617 19.4895 24.2617 14.0263Z"
        fill={color}
      />
    </svg>
  );
}
