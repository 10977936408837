// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconLinkedIn({
  className,
  onClick,
  width = 28,
  height = 28,
  color = "#0AA781",
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.392 21.3955H18.2828V16.5223C18.2828 15.3603 18.2595 13.8647 16.6612 13.8647C15.0407 13.8647 14.7933 15.1293 14.7933 16.4372V21.3955H11.683V11.375H14.6697V12.74H14.7105C15.1282 11.9537 16.1432 11.1218 17.6598 11.1218C20.8098 11.1218 21.3932 13.1962 21.3932 15.8947L21.392 21.3955ZM8.17133 10.0042C7.93398 10.0045 7.6989 9.95793 7.47957 9.86721C7.26024 9.77648 7.06098 9.64336 6.8932 9.47547C6.72542 9.30759 6.59243 9.10823 6.50185 8.88885C6.41126 8.66946 6.36487 8.43435 6.36533 8.197C6.36556 7.83981 6.47171 7.4907 6.67035 7.19384C6.86898 6.89697 7.15119 6.66567 7.48128 6.52919C7.81138 6.39272 8.17453 6.35719 8.52481 6.4271C8.87509 6.49701 9.19678 6.66922 9.44919 6.92196C9.7016 7.17469 9.8734 7.4966 9.94286 7.84698C10.0123 8.19735 9.97632 8.56046 9.83941 8.89037C9.70251 9.22029 9.47085 9.50219 9.17372 9.70045C8.8766 9.8987 8.52736 10.0044 8.17017 10.0042H8.17133ZM9.73 21.3955H6.6115V11.375H9.73V21.3955ZM22.9483 3.5H5.05167C4.193 3.5 3.5 4.17667 3.5 5.01317V22.9868C3.5 23.8233 4.193 24.5 5.04933 24.5H22.9448C23.8 24.5 24.5 23.8233 24.5 22.9868V5.01317C24.5 4.17783 23.8 3.5 22.9437 3.5H22.9483Z"
        fill={color}
      />
    </svg>
  );
}
