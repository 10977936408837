// @flow
import * as React from "react";
import { IconProps } from "../helpers/types.d";

export function IconEmail({
  className,
  onClick,
  width = 28,
  height = 28,
  color = "#0AA781",
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1666 22.1667C15.1666 21.7701 15.2133 21.3851 15.2716 21.0001H4.66659V9.33341L13.9999 15.1667L23.3333 9.33341V15.2717C24.1733 15.4117 24.9549 15.7034 25.6666 16.1117V7.00008C25.6666 5.71675 24.6166 4.66675 23.3333 4.66675H4.66659C3.38325 4.66675 2.33325 5.71675 2.33325 7.00008V21.0001C2.33325 22.2834 3.38325 23.3334 4.66659 23.3334H15.2716C15.2133 22.9484 15.1666 22.5634 15.1666 22.1667ZM23.3333 7.00008L13.9999 12.8334L4.66659 7.00008H23.3333ZM23.3333 25.6667V23.3334H18.6666V21.0001H23.3333V18.6667L26.8333 22.1667L23.3333 25.6667Z"
        fill={color}
      />
    </svg>
  );
}
