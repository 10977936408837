// @flow
import * as React from "react";
import { cn } from "../helpers/twMerge";
import { IndicatorGood } from "../icons/IndicatorGood";
import { IndicatorMedium } from "../icons/IndicatorMedium";
import { IndicatorBad } from "../icons/IndicatorBad";

type ResultProps = {
  variant: "good" | "medium" | "bad" | undefined;
};

function ResultIndicator({ variant }: ResultProps) {
  switch (variant) {
    case "good":
      return <IndicatorGood />;
    case "medium":
      return <IndicatorMedium />;
    case "bad":
      return <IndicatorBad />;
    default:
      return null;
  }
}

function ResultTitle({ variant }: ResultProps) {
  switch (variant) {
    case "good":
      return (
        <h2 className="text-lg font-bold leading-6">
          Máte nízke riziko stukovatenia pečene
        </h2>
      );
    case "medium":
      return (
        <h2 className="text-lg font-bold leading-6">
          Máte stredné riziko stukovatenia pečene
        </h2>
      );
    case "bad":
      return (
        <h2 className="text-lg font-bold leading-6">
          Máte vysoké riziko stukovatenia pečene
        </h2>
      );
    default:
      return null;
  }
}

function ResultDescription({ variant }: ResultProps) {
  const classes =
    "text-base leading-6 font-medium [&_p]:mb-[1ch] [&_p:last]:mb-0";

  switch (variant) {
    case "good":
      return (
        <div className={classes}>
          <p>
            Dobre správy! Na základe vašich odpovedí sa zdá, že vaša pečeň je
            zdravá. Je skvelé, že si na seba dávate pozor.
          </p>
          <p>
            Pokiaľ by ste mali záujem ešte viac zlepšiť svoju životosprávu alebo
            mať lepší prehľad o svojom zdraví, som tu pre vás a môžeme
            pokračovať v rozhovore. Nezabúdajte však, že tento rozhovor
            nenahrádza lekársku diagnostiku.
          </p>
        </div>
      );
    case "medium":
      return (
        <div className={classes}>
          <p>
            Podľa výsledkov dotazníka by ste mali začať venovať viac pozornosti
            svojej pečeni. Teraz vás možno ešte nič nebolí, no ak problém
            nebudete riešiť, môžete si spôsobiť vážnejšie zdravotné problémy.
          </p>
          <p>
            Dobrou správou je, že vaše riziko môžete ovplyvniť a aj zvrátiť
            zmenou životosprávy. Je dôležité začať sa o svoje zdravie starať
            teraz, aby ste sa vyhli komplikáciám v budúcnosti. Som tu, aby som
            vám poradil, ako na to. Rád by som však pripomenul, že tento
            rozhovor nenahrádza lekársku diagnostiku.
          </p>
        </div>
      );
    case "bad":
      return (
        <div className={classes}>
          <p>
            Zdá sa, že vaše riziko stukovatenia pečene je vyššie. Určite
            odporúčam konzultovať ho s lekárom a podstúpiť ďalšie vyšetrenia.
          </p>
          <p>
            NAFLD môže byť tichý problém, ktorý nemá viditeľné príznaky, ale je
            veľmi dôležité riešiť ho čo najskôr. Ak máte záujem o ďalšie
            informácie alebo tipy, ako sa postarať o svoje zdravie, som tu pre
            vás. Nezabudnite, že tento rozhovor nenahrádza lekársku diagnostiku.
          </p>
        </div>
      );
    default:
      return null;
  }
}

export function Result({ variant }: ResultProps) {
  return (
    <div
      className={cn(
        "flex w-full max-w-[300px] flex-col gap-y-8 rounded-2xl border border-solid px-5 py-6 sm:max-w-[465px]" +
          " text-left",
        {
          "border-mint-green bg-mint-green-10 text-mint-green":
            variant === "good",
          "border-energy-orange bg-energy-orange-10 text-energy-orange":
            variant === "medium",
          "border-warning-red bg-warning-red-10 text-warning-red":
            variant === "bad",
        },
      )}
    >
      <ResultIndicator variant={variant} />
      <ResultTitle variant={variant} />
      <ResultDescription variant={variant} />
    </div>
  );
}
