// @flow
import * as React from "react";
import { cn } from "../helpers/twMerge";

type ButtonProps = {
  text: string;
  onClick: () => void;
  className?: string;
};

export function Button({ text, onClick, className }: ButtonProps) {
  return (
    <button
      className={cn(
        "inline-flex w-full cursor-pointer items-center justify-center gap-2.5 rounded bg-zhiva-orange p-4 text-sm font-bold uppercase text-zhiva-brown hover:bg-zhiva-orange-125",
        className,
      )}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          // Do nothing
        }
      }}
      type={"button"}
    >
      {text}
    </button>
  );
}
