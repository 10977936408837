// @flow
import * as React from "react";
import { AnswerBubble } from "./AnswerBubble";
import { cn } from "../helpers/twMerge";
import { Input } from "./Input";

type AnswersProps = {
  id: number;
  answers: {
    text: string;
    score?: number;
    value?: string;
    type: string;
    key: string;
    sex?: string;
    prompt?: string;
  }[];
  onAnswer: (
    value: string | number,
    id: number,
    key: string,
    score: number | null,
    prompt?: string,
  ) => void;
  flexStart?: boolean;
  register?: any;
  formState?: any;
  answered?: string | number;
  getValues?: any;
};

function getVekScore(value: any) {
  if (value < 35) {
    return 0;
  }
  if (value >= 35) {
    return 2;
  }

  return 0;
}

function getHmotnostScore(value: number, vyska: number) {
  const bmi = value / ((vyska / 100) * (vyska / 100));
  if (bmi < 25) {
    return 0;
  }
  if (bmi >= 25 && bmi < 29.9) {
    return 1;
  }
  if (bmi >= 30 && bmi < 34.9) {
    return 2;
  }
  if (bmi >= 35) {
    return 3;
  }

  return 0;
}

function getInputType(key: string) {
  switch (key) {
    case "email":
      return "email";
    case "chat":
      return "text";
    default:
      return "number";
  }
}

export function Answers({
  id,
  answers,
  onAnswer,
  flexStart,
  register,
  answered,
  getValues,
  formState,
}: AnswersProps) {
  const handleOnAnswer = (
    value: string | number,
    score: number | null,
    key: string,
    prompt?: string,
  ) => {
    if (answered) {
      return;
    }
    onAnswer(value, id, key, score, prompt);
  };

  const handleOnSend = (name: string, key: string) => {
    const value = getValues(name);
    let score = null;
    let prompt = undefined;

    if (key === "vek") {
      score = getVekScore(value);
      prompt = `Užívateľ má ${value} rokov.`;
    }

    if (key === "hmotnost") {
      score = getHmotnostScore(value, getValues("vyska") ?? 0);
      if (score === 1) {
        prompt = `Má index telesnej hmotnosti ${score}, čo znamená, že má miernu nadváhu.`;
      } else if (score === 2) {
        prompt = `Má index telesnej hmotnosti ${score}, čo znamená, že má nadváhu.`;
      } else if (score === 3) {
        prompt = `Má index telesnej hmotnosti ${score}, čo znamená, že má vážnu nadváhu.`;
      }
    }

    onAnswer(value, id, key, score, prompt);
  };

  return (
    <div
      className={cn("sm: flex w-full flex-row flex-wrap justify-center gap-3", {
        "justify-start": flexStart,
        "justify-end": answered,
      })}
    >
      {answers.map((answer) => (
        <>
          {answer.type === "button" ? (
            <AnswerBubble
              text={answer.text}
              score={answer.score}
              value={answer.value}
              answerKey={answer.key}
              onClick={handleOnAnswer}
              answered={answered}
              answerSex={answer.sex ?? null}
              sex={getValues("pohlavie")}
              prompt={answer.prompt}
            />
          ) : null}

          {answer.type === "input" ? (
            <>
              {answered && (
                <AnswerBubble
                  answerKey={answer.key}
                  text={getValues(answer.text) || answer.text}
                  onClick={handleOnAnswer}
                  answered={answered}
                  input={true}
                />
              )}
              <Input
                answerKey={answer.key}
                name={answer.text}
                register={register}
                onSend={handleOnSend}
                hide={answered}
                type={getInputType(answer.key)}
                formState={formState}
              />
            </>
          ) : null}
        </>
      ))}
    </div>
  );
}
